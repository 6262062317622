<template>
  <section>
    <v-container class="py-0" v-if="asuulga">
      <v-row class="mt-10 mx-2">
        <v-col lg="6" md="6" cols="12">
          <div class="text-start" v-if="uuser">
            <h3>{{ asuulga.title }}</h3>
          </div>
        </v-col>
        <v-col lg="6" md="6" cols="12" class="text-end">
          <v-btn small color="primary" dark @click="_moreInfo()">More2</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-container v-if="isGrid" style="min-height: 480px; overflow-y: scroll">
        <v-row no-gutters>
          <v-col md="12" lg="12" sm="12" class="mx-auto">
            <v-toolbar flat color="#ececec">
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="black"></v-tabs-slider>
                <v-tab
                  v-for="(cat, catindex) in categories"
                  :key="catindex"
                  class="text-lowercase"
                >
                  {{ cat.name }} (
                  <span v-if="cat.questions">{{ cat.questions.length }}</span>
                  <span v-else>0</span> )
                </v-tab>
              </v-tabs>
            </v-toolbar>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(cat, catindex) in categories" :key="catindex">
                <v-simple-table class="bborder">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="width: 1%">No.</th>
                        <th>Асуулт</th>
                        <th
                          class="blue--text"
                          v-for="(answer, answerIndex) in cat.answers"
                          :key="'headanswer' + answerIndex"
                        >
                          {{ answer.name }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(question, qindex) in cat.questions"
                        :key="qindex + question.id"
                      >
                        <td style="width: 1%">{{ qindex + 1 }}</td>
                        <td>{{ question.name }}</td>
                        <td
                          @click="_checkAnswerPerClassGroups(question, answer)"
                          style="cursor: pointer"
                          v-for="(answer, answerIndex) in question.answers"
                          :key="'answer' + answerIndex"
                        >
                          <span v-if="answer.numberOfAnswered"
                            >{{ answer.numberOfAnswered }}
                          </span>
                          <span v-else> - </span>
                        </td>
                      </tr>
                    </tbody>
                  </template></v-simple-table
                ></v-tab-item
              ></v-tabs-items
            ></v-col
          ></v-row
        ></v-container
      ></v-container
    >

    <v-dialog
      v-if="showClassGroupChartDialog"
      v-model="showClassGroupChartDialog"
      width="50%"
    >
      <v-card>
        <v-card-title
          >{{ selectedQuestion.name }} - {{ selectedAnswer.numberOfAnswered }} /
          <span class="blue--text ml-2">{{
            selectedAnswer.name
          }}</span></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text> </v-card-text>
        <v-card-text>
          <AsuulgaChartsSimple
            :iidd="selectedAnswer.id"
            :xx="selectedAnswer.xx"
            :yy="selectedAnswer.yy"
            v-bind="$attrs"
          ></AsuulgaChartsSimple>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="showClassGroupChartDialog = false"
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
import AsuulgaChartsSimple from "./AsuulgaChartsSimple";
// import AsuulgaCharts from "./AsuulgaCharts.vue";
export default {
  data: () => ({
    selectedAnswer: null,
    selectedQuestion: null,
    showClassGroupChartDialog: false,
    toBeNumberOfParticipants: null,
    sendDialog: false,
    isGrid: true,
    tab: null,
    items: ["web", "shopping", "videos", "images", "news"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    asuulga: null,
    categories: null,
    prePath: null,
    uuser: {
      type: Object,
      required: true,
    },
    school: null,
    bgDetailColor: "#ececec",
  }),
  components: {
    // AsuulgaCharts,
    AsuulgaChartsSimple,
  },
  props: {
    sorilId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this._getData();
  },
  methods: {
    _moreInfo() {
      console.log("_more");
      this.$router.push({
        name: "AsuulgaResultsManagerPerGroup",
        params: { sorilId: this.asuulga.ref.path },
      });
    },
    _checkAnswerPerClassGroups(question, answer) {
      console.log(question.ref.path, answer.ref.path, answer.id);
      this.selectedQuestion = question;
      this.selectedAnswer = answer;
      this.selectedAnswer.xx = null;
      this.selectedAnswer.yy = null;

      question.ref
        .collection("answers")
        .doc(answer.id)
        .collection("results")
        .get()
        .then((docs) => {
          this.selectedAnswer.xx = [];
          this.selectedAnswer.yy = [];
          docs.forEach((doc) => {
            this.selectedAnswer.xx.push(doc.id);
            this.selectedAnswer.yy.push(doc.data()["numberOfAnswered"]);
          });
          this.showClassGroupChartDialog = true;
        });
    },
    async _getData() {
      fb.db
        .doc(this.sorilId)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            this.asuulga = doc.data();
            this.asuulga.ref = doc.ref;
            this.asuulga.id = doc.id;
            await this._findCategories();
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },

    _getQuestionsOfCategory(cat) {
      cat.ref
        .collection("questions")
        .where("deleted", "==", false)
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            question.id = doc.id;
            question.ref = doc.ref;
            question.answers = [];
            question.answered = false;
            question.countAnswers = 0;
            question.answers = [];
            console.log(question.id);
            doc.ref
              .collection("answers")
              .orderBy("answerIndex", "asc")
              .onSnapshot((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach(async (doc) => {
                  let answer = doc.data();
                  answer.id = doc.id;
                  answer.ref = doc.ref;
                  console.log(answer.ref.path, answer.numberOfAnswered);
                  question.answers.push(answer);
                });
              });

            cat.questions.push(question);
          });
        });
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.ref = catDoc.ref;
            cat.questions = null;
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.outlined-card {
  border: 1px solid #e0e0e0;
}
.outlined-card:hover {
  border: 4px solid #e0e0e0;
}
</style>